import { createSlice , createAsyncThunk } from '@reduxjs/toolkit'

const initialState = {
    loading: false,
    userData: null,
    username: null,
}

export const gettingUser = createAsyncThunk('user/gettingUser',async (argToken,thunkAPI)=>{
  const {rejectWithValue} = thunkAPI;
  try{
    const headers = {
      "Content-Type": "application/json; charset=utf8",
      Authorization: `Bearer ${argToken?.access || argToken}`, // adding the token
    };
      const res = await fetch(`https://ccl.apps.domefdh.com/api/user/`,{
        headers,
      });
      const data = await res.json();
      return data;
  }catch(error){return rejectWithValue(error)}
})


const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    removeUser: (state,action)=>{
       
        return {
            ...state,
            userData: null,
            username: null
        }
    }
  },
  extraReducers:
    (builder) => {
      builder.addCase(gettingUser.pending,(state,action)=>{
       
        return {
            ...state,
            loading: true
        }
    })
    .addCase(gettingUser.fulfilled,(state,action)=>{
      
              
        return {
            ...state,
            loading : false ,
            userData : action.payload,
            username : action.payload.username,
        }
    })
    .addCase(gettingUser.rejected,(state,action)=>{
      
        console.log(action.error.message)
        // if(action.payload?.response?.status === 401){
        //     return{
        //       ...state,
        //       userData: null,
        //       username: null,
        //       loading: false,
        //     }
        // }
        return {
            ...state,
            loading: false,
        }
    })
    }
})


export const { removeUser } = UserSlice.actions
export default UserSlice.reducer
