import { configureStore } from "@reduxjs/toolkit"
import logSlice from "./Slice1"
import windloadSlice from "./SliceWindload"
import userSlice from "./UserSlice"
import UserSlice from "../Auth/Login/UserAuth"
import LoginSlice from "../Auth/Login/LoginSlice"
import emailSlice from "./emailSlice"
import countrySlice from "./countrySlice"

export const store = configureStore({
  reducer: {
    logSlice: logSlice,
    windloadSlice: windloadSlice,
    userSlice: userSlice,
    UserSlice: UserSlice,
    loginSlice: LoginSlice,
    emailSlice: emailSlice,
    countrySlice: countrySlice,
  },
})
