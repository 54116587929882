import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  send_email: false,
};

const emailSlice = createSlice({
  name: "emailSlice",
  initialState,
  reducers: {
    sendEmail: (state, action) => {
      return {
        ...state,
        send_email: action.payload,
      };
    },
    setSendEmailTrue: (state) => {
      state.send_email = true;
    },
  },
  extraReducers: {},
});

export const { setSendEmailTrue } = emailSlice.actions;
export default emailSlice.reducer;